import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutUiControlService {
  private readonly isBreadcrumbVisible = new BehaviorSubject<boolean>(true);
  readonly isBreadcrumbVisible$ = this.isBreadcrumbVisible.asObservable();

  private _showWebBackBtn = signal(false);
  public isShowWebBackBtn = this._showWebBackBtn.asReadonly();
  constructor() {}

  showBreadcrumb() {
    this.isBreadcrumbVisible.next(true);
  }

  hideBreadcrumb() {
    this.isBreadcrumbVisible.next(false);
  }

  showWebBackBtn() {
    this._showWebBackBtn.set(true);
  }
  hideWebBackBtn() {
    this._showWebBackBtn.set(false);
  }
}
